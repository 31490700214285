<template>
  <div class="chargeoff-con">
    <NavBar
      title="核销"
      left-arrow
      @click-left="goBack"
      v-if="isWeixin_status == false"
    />
    <Cell :title="'服务项目：'+detail_info.service.name" >
    </Cell>
    <Cell :title="'消耗积分：'+detail_info.score" >
    </Cell>
    <Cell :title="'订单编号：'+ detail_info.ordersn"   >
    </Cell>
    <Cell :title="'下单时间：'+detail_info.create_time" >
    </Cell>
    <div class="scan-con" v-if="detail_info.service.is_verify == 1">
      <div class="scan-img">
        <img v-lazy="detail_info.url" alt="">
      </div>
      <p>请将该二维码展示给商家核销</p>
    </div>
  </div>
</template>
<script>
import washCar from "@/api/washCar";
import { NavBar, Cell } from 'vant';
export default {
  components: {
    NavBar,
    Cell
  },
  data() {
    return {
      detail_info: {}
    }
  },
  created() {
    if (this.$route.query.type) {
      this.getDetail(this.$route.query.type);
    }
  },
  methods: {
    async getDetail(idx) {
      try {
        const res = await washCar.getStoreServePayDetail(idx);
        if (res.code == 200) {
          this.detail_info = res.data;
        } else {
          throw(res.msg);
        }
      } catch (error) {
        console.log(error);
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.chargeoff-con {
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-color: rgba(244,244,244,1);
  .scan-con {
    width: 100%;
    height: 60vh;
    background: #ffffff;
    padding: 20px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #000000;
    .scan-time {
      width: 100%;
      height: 30px;
      display: flex;
      font-size: 14px;
      border-bottom: 1px solid #dfdfdf;
      justify-content: space-between;
    }
    .scan-title {
      width: 100%;
      height: 60px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      text-align: center;
      margin-top: 10px;
      span:nth-child(2) {
        color: #000000;
        font-size: 20px;
      }
    }
    .scan-img {
      width: 250px;
      height: 250px;
      border-radius: 3px;
      display: flex;
      align-items: center;
      margin: 10px;
      border: 1px solid #dfdfdf;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>